import React from 'react';
import Case from '../components/case'

const CaseRaizen = (props) => (
    <Case 
        seoTitle="Forle Marketing Case Raizen"
        images={["1.jpg", "2.jpg", "3.jpg", "4.jpg"]} 
    >  
          <h1>Case Raizen</h1>
          <div className="row justify-content-center my-5">
              <div className="col-sm-12 col-lg-6">
          <p>A Forle criou uma <strong>experi&ecirc;ncia &uacute;nica para ambos os p&uacute;blicos</strong>. Para <strong>Camel</strong>, foram desenvolvidas v&aacute;rias op&ccedil;&otilde;es de entretenimento: <strong>simulador de <em>pitstop</em></strong>, visando a audi&ecirc;ncia apaixonada por carros de corrida e <strong>F&oacute;rmula-1</strong>, <strong>fum&oacute;dromo</strong>, espa&ccedil;o para <strong>socializa&ccedil;&atilde;o</strong>, e <strong>mesa de pebolim</strong>. Para <strong>Winston</strong>, um ambiente de competi&ccedil;&atilde;o divertido, mesa de <strong><em>Air Hockey</em></strong> que utiliza o ar para levitar o disco.</p></div>
          <div className="col-sm-12 col-lg-6">
              <p> Al&eacute;m disso, foram feitos balc&otilde;es com exibi&ccedil;&atilde;o dos produtos, inspirados nos elementos e grafismos das marcas. <strong>Espa&ccedil;os convidativos&nbsp;</strong>que trazem intera&ccedil;&atilde;o, sem se tornar em algo cansativo. <strong>Pensados para o desenvolvimento das marcas</strong>, os estandes alcan&ccedil;aram resultados produtivos. &nbsp;Desde <strong>estrutura, tecnologia e desenvolvimento</strong>, tudo foi criado pela equipe Forle.&nbsp;</p>
        </div></div>
    </Case>
)

export default CaseRaizen